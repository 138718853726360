<template>
  <Row type="flex" justify="space-between">
    <Button type="primary" size="large" @click="show_edit = !show_edit; id_edit = 'add'">Новая акция</Button>

    <Space>
      <Dropdown :trigger="['click']">
        <Button>Сортировка <DownOutlined/></Button>
        <template #overlay>
          <Menu v-model:selectedKeys="sort" @click="handleSort">
            <MenuItem key="name.asc">
              По имени А-Я
            </MenuItem>
            <MenuItem key="name.desc">
              По имени Я-А
            </MenuItem>
            <MenuItem key="sort.asc">
              По порядку
            </MenuItem>
          </Menu>
        </template>
      </Dropdown>
    </Space>

  </Row><br>

  <Card title="" class="table">
    <Table :columns="columns" :data-source="list" :pagination="pagination" :loading="loading" rowKey="id"
           @change="handleTableChange">

      <template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
        <div class="dropdown-search-panel">
          <Input :placeholder="`Поиск по ${column.title}`" :value="selectedKeys[0]"
                 @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                 @pressEnter="confirm()"/>
          <Space>
            <Button type="primary" @click="confirm()">
              <template #icon>
                <SearchOutlined/>
              </template>
              Найти
            </Button>
            <Button @click="clearFilters">Сбросить</Button>
          </Space>
        </div>
      </template>
      <template #customFilterIcon="{ filtered }">
        <SearchOutlined
            :style="{background: filtered?'rgba(16,142,233,0.1)':undefined, padding: '9px', borderRadius: '6px'}"/>
      </template>

      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'name'">
          <Space>
            <Image v-if="record.cover" :src="record.cover" style="border-radius: 6px; height: 44px; width: auto"/>
            <Avatar shape="square" :size="44" v-else>
              <template #icon>
                <FileImageOutlined/>
              </template>
            </Avatar>
            <div>
              {{ record.name }}<br>
              <small><a :href="'/actions/'+record.slug" target="_blank">/actions/{{ record.slug }}</a></small>
            </div>
          </Space>
        </template>
        <template v-else-if="column.key === 'actions'">
          <Space>
            <Popconfirm :title="(record.status ? 'Снять с публикации' : 'Опубликовать') + '?'"
                        placement="topLeft" @confirm="status(record)">
              <Button type="text" class="silver-color">
                <template #icon>
                  <UnlockFilled v-if="record.status"/>
                  <LockFilled style="color: red" v-else/>
                </template>
              </Button>
            </Popconfirm>
            <Button type="text" class="silver-color" @click="show_edit = !show_edit; id_edit = record.id">
              <template #icon>
                <FormOutlined/>
              </template>
            </Button>
            <Popconfirm title="Удалить запись?" placement="topLeft" @confirm="remove(record.id)">
              <Button type="text" class="silver-color">
                <template #icon>
                  <DeleteFilled/>
                </template>
              </Button>
            </Popconfirm>
          </Space>
        </template>
      </template>
    </Table>
  </Card>

  <Action :show="show_edit" :id="id_edit" @close="show_edit = false" @update="val => { id_edit = val; load() }"/>
</template>

<script setup>
import {
  Image,
  Card,
  Row,
  Space,
  Dropdown,
  Button,
  Menu,
  Popconfirm,
  MenuItem,
  Avatar,
  Input,
  Table,
  notification
} from "ant-design-vue";
import {
  DownOutlined,
  SearchOutlined,
  FormOutlined,
  DeleteFilled,
  UnlockFilled,
  LockFilled,
  FileImageOutlined
} from '@ant-design/icons-vue'
import {reactive, ref} from 'vue'
import {useRoute} from "vue-router";
import router from "@/router";
import Action from '../../../components/Action';

const route = useRoute()

const columns = [
  {
    title: 'Название',
    key: 'name',
    dataIndex: 'name',
    customFilterDropdown: true,
    defaultFilteredValue: route.query.name ? [route.query.name] : null
  },
  {
    title: 'Дата создания',
    key: 'created_at',
    dataIndex: 'created_at',
  },
  {
    title: 'Порядок',
    key: 'sort',
    dataIndex: 'sort',
  },
  {
    align: 'right',
    key: 'actions',
  },
];

let search = {
  name: route.query.name
}

const visibleFilter = ref(false)
const filters = reactive({})

const sort = ref([route.query.order ? route.query.order : 'sort.asc'])
const handleSort = ({key}) => {
  sort.value = [key]
  load()
}
const pagination = reactive({
  total: 0,
  current: +route.query.page ? +route.query.page : 1,
  pageSize: 10
})
const loading = ref(false)
const list = ref([])
const load = async () => {
  loading.value = true

  let query = `page=${pagination.current}&order=${sort.value}&`
      + `${Object.keys(search).map(key => search[key] ? key + '=' + search[key] : null).join('&')}&`
      + `${Object.keys(filters).map(key => {
        if (filters[key]) return key
      }).filter(v => v).join('&')}`

  let urlParams = new URLSearchParams(query);
  await router.push({query: Object.fromEntries(urlParams)})

  try {
    const {data} = await $axios.get(`actions?${query}`)
    list.value = data.data
    pagination.total = data.total
    pagination.pageSize = data.per_page
  }catch (er){}
  loading.value = false
}
load()

const handleTableChange = (pag, filters) => {
  pagination.current = pag.current
  search = filters
  load()
}

const status = async (record) => {
  loading.value = true
  try {
    const {data} = await $axios.post(`actions/${record.id}/status`, {value: !record.status})
    record.status = data.status
    notification.open({
      class: data.status ? 'success' : 'warning',
      message: 'Готово',
      description: 'Запись ' + (data.status ? 'опубликована' : 'снята с публикации'),
    })
  }catch(er){}
  loading.value = false
}
const remove = async (id) => {
  loading.value = true
  try {
    await $axios.delete(`actions/${id}`)
    load()
      notification.open({
        class: 'warning',
        message: 'Готово',
        description: 'Запись удалена',
      })
  }catch (er){}
  loading.value = false
}

const show_edit = ref(false)
const id_edit = ref('add')
</script>
