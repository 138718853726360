<template>
  <Drawer :title="edit ? 'Редактировать' : 'Создать'" :width="width" v-model:visible="visible"
          :body-style="{ paddingBottom: '80px' }">

    <Skeleton active v-if="loading"/>
    <Form v-show="!loading" :model="formState" :rules="rules" layout="vertical" @finish="submit">
      <Card>
        <Row type="flex" justify="space-between" align="middle">
          <Space>

            <Image v-if="formState.cover" :src="formState.cover" style="border-radius: 6px; height: 90px; width: auto"/>
            <Avatar shape="square" :size="90" v-else>
              <template #icon>
                <FileImageOutlined/>
              </template>
            </Avatar>

            <Space direction="vertical">
              <span>Размер изображения 1400x600 пикселей</span>
              <Upload :customRequest="() => {}" :show-upload-list="false" @change="handleChange">
                <Button size="small">Загрузить баннер</Button>
              </Upload>
              <Button size="small" danger v-show="formState.cover" @click="remove_image">Удалить</Button>
            </Space>
          </Space>

          <Space>
            <Tooltip>
              <template #title>{{ formState.status ? 'Опубликована' : 'Скрыта' }}</template>
              <Button type="text" class="silver-color" size="large" @click="formState.status = !formState.status">
                <template #icon>
                  <UnlockFilled :style="{ fontSize: '32px' }" v-if="formState.status"/>
                  <LockFilled style="color: red" :style="{ fontSize: '32px' }" v-else/>
                </template>
              </Button>
            </Tooltip>

            <FormItem name="sort" label="Порядок" style="width: 60px;">
              <InputNumber v-model:value="formState.sort" step="1" size="large" style="width: 60px"/>
            </FormItem>
          </Space>
        </Row>
      </Card>
      <br><br>

      <Card title="Общие данные">
        <Row :gutter="16">
          <Col :span="24">
            <FormItem name="name" label="Заголовок">
              <Input v-model:value="formState.name" size="large" placeholder="Введите заголовок"/>
            </FormItem>
          </Col>
          <Col :span="24">
            <div class="ant-col ant-form-item-label">
              <label>Короткое описание</label>
            </div>
            <div class="editorjs" id="editor_lead"></div>
          </Col>
          <Col :span="24">
            <div class="ant-col ant-form-item-label">
              <label>Подробное описание</label>
            </div>
            <div class="editorjs" id="editor_body"></div>
          </Col>
          <Col :span="24">
            <Button type="primary" size="large" html-type="submit" :loading="saving">Сохранить</Button>
          </Col>
        </Row>
      </Card>
    </Form>

  </Drawer>
</template>

<script setup>
import {
  Skeleton,
  Drawer,
  Card,
  Row,
  Col,
  Avatar,
  Form,
  FormItem,
  Select,
  SelectOption,
  InputPassword,
  Input,
  InputNumber,
  Textarea,
  Button,
  Tooltip, Upload, Image,
  Space, notification, Alert
} from "ant-design-vue";
import {FileImageOutlined, UploadOutlined, PlusOutlined, UnlockFilled, LockFilled} from '@ant-design/icons-vue';
import {reactive, ref, toRaw, toRefs, watch, getCurrentInstance} from 'vue';

import EditorJS from '@editorjs/editorjs';
import Paragraph from 'editorjs-paragraph-with-alignment';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Table from '@editorjs/table';
import LinkWithTarget from 'editorjs-link-with-target'

let editor_lead = null
let editor_body = null

const {emit} = getCurrentInstance()
const props = defineProps({
  show: {
    type: Boolean,
    require: true
  },
  id: {
    type: [String, Number],
    required: true
  }
})

const formState = reactive({
  status: true,
  name: '',
  lead: null,
  body: null,
  cover: null,
  sort: 0,
});
const rules = {
  name: [
    {
      required: true,
      min: 2,
      message: 'Не менее 2 символов',
      trigger: 'blur',
    },
  ],
};
const customError = reactive({})
let fileList = null;

const {show, id} = toRefs(props)
const width = ref(1054);
const visible = ref(false)
const loading = ref(false)
const edit = ref(false)
watch(show, async val => {
  let w = window.innerWidth - window.innerWidth * 0.2
  width.value = w > 1054 ? 1054 : w
  visible.value = val
  edit.value = id.value !== 'add'

  if (val) {
    if (!editor_lead) {
      editor_lead = new EditorJS({
        holder: 'editor_lead',
        placeholder: 'Добавьте содержание здесь',
        logLevel: 'ERROR',
        data: formState.lead,
        tools: {
          paragraph: {
            class: Paragraph,
            inlineToolbar: true,
          },
          header: {
            class: Header,
            config: {
              placeholder: 'Введите заголовок',
              levels: [2, 3, 4],
              defaultLevel: 3
            }
          },
          list: {
            class: List,
            inlineToolbar: true,
          },
          table: Table,
          link: {
            class: LinkWithTarget
          },
        },
        i18n: {
          messages: {
            tools: {
              link: {
                'Open in new window': 'Открыть ссылку в новом окне',
                Save: 'Сохранить',
                'Add a link': 'Вставьте ссылку'
              }
            }
          }
        }
      });
      editor_body = new EditorJS({
        holder: 'editor_body',
        placeholder: 'Добавьте содержание здесь',
        logLevel: 'ERROR',
        data: formState.body,
        tools: {
          paragraph: {
            class: Paragraph,
            inlineToolbar: true,
          },
          header: {
            class: Header,
            config: {
              placeholder: 'Введите заголовок',
              levels: [2, 3, 4],
              defaultLevel: 3
            }
          },
          list: {
            class: List,
            inlineToolbar: true,
          },
          table: Table,
          link: {
            class: LinkWithTarget
          },
        },
        i18n: {
          messages: {
            tools: {
              link: {
                'Open in new window': 'Открыть ссылку в новом окне',
                Save: 'Сохранить',
                'Add a link': 'Вставьте ссылку'
              }
            }
          }
        }
      });
      await editor_lead.isReady;
      await editor_body.isReady;
    }

    if (id.value === 'add') {
      formState.status = true
      formState.name = ''
      formState.lead = null
      formState.body = null
      formState.cover = null
      formState.sort = 0
      editor_lead.clear()
      editor_body.clear()
    } else {
      loading.value = true
      await $axios.get('actions/' + id.value).then(r => {
        formState.status = r.data.status
        formState.name = r.data.name
        formState.lead = JSON.parse(r.data.lead)
        formState.body = JSON.parse(r.data.body)
        formState.cover = r.data.cover
        formState.sort = r.data.sort
        if (formState.lead) editor_lead.render(formState.lead)
        if (formState.body) editor_body.render(formState.body)
      }).catch(error => {
        notification.open({
          class: 'error',
          message: error.response.status,
          description: error.response.statusText,
        })
      })
      loading.value = false
    }
  }
})

const saving = ref(false)
const submit = async () => {
  saving.value = true

  formState.lead = await editor_lead.save()
  formState.body = await editor_body.save()

  const formData = new FormData()
  let obj = toRaw(formState)
  for (let key in obj) formData.append(key, obj[key])
  formData.append('lead', formState.lead.blocks.length ? JSON.stringify(formState.lead) : '')
  formData.append('body', formState.body.blocks.length ? JSON.stringify(formState.body) : '')
  if (fileList) formData.append('file', fileList)
  if (!formState.cover) formData.append('remove_image', true)

  await $axios.post('actions' + (edit.value ? `/${id.value}` : ''), formData).then(r => {
    emit('update', r.data.id)
    notification.open({
      class: 'success',
      message: 'Готово',
      description: 'Данные сохранены'
    })
  }).catch(error => {
    if (error.response) {
      if (error.response.status === 422) {
        try {
          for (const [key, value] of Object.entries(error.response.data.errors)) customError[key] = value
        } catch (error) {
          console.info(error)
        }
      } else {
        notification.open({
          class: 'error',
          message: error.response.status,
          description: error.response.statusText,
        })
      }
    }
  })
  saving.value = false
}

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

const handleChange = info => {
  fileList = info.file.originFileObj
  getBase64(info.file.originFileObj, base64Url => {
    formState.cover = base64Url;
  });
}
const remove_image = () => {
  fileList = null
  formState.cover = null
}

</script>
